<template>
  <div class="area_view">
    <TableHead title="개인정보 수집 및 이용동의">
      <template v-slot:headLeft>
        <!-- <p class="desc_tip">
          <span class="ico_purchase ico_exclam"></span>더 자세한 내용에 대해서는
          
          <a
            href="https://www.kakaocorp.com/policy/privacyPolicy/corporateSitePrivacyPolicy"
            class="link_tip"
            target="_blank"
            >개인정보 처리방침</a
          >을 참고하시기 바랍니다.
        </p> -->
      </template>
    </TableHead>
    <Board class="tbl_border" :dataList="[{}]" :disableHover="true" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 410px" />
        <col style="width: 410px" />
        <col style="width: 420px" />
      </template>
      <template v-slot:tr>
        <th>목적</th>
        <th>항목</th>
        <th>보유기간</th>
      </template>
      <template v-slot:rows>
        <tr>
          <td>협력사 담당자 식별 및 관리</td>
          <td>이름, 직급, 직책</td>
          <td rowspan="2">동의철회 시 지체없이 파기</td>
        </tr>
        <tr>
          <td>공지사항 안내 및 서비스 처리를 위한 커뮤니케이션</td>
          <td>이메일, 휴대전화번호</td>
        </tr>
      </template>
    </Board>
    <div class="agree_term">
      <div class="area_left">
        <p class="desc_tip">
          * 서비스 이용에 필요한 최소한의 개인정보로, 동의 거부 시 서비스 이용에 제한이 있습니다.
        </p>
      </div>
      <div class="area_right">
        <CheckboxOnlyOne
          id="1"
          :isSelected.sync="dataSync.priTermsYn"
          text="[필수] 개인정보 수집 및 이용약관 동의합니다."
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";

export default {
  name: "PersonalAgree",
  components: {
    TableHead,
    Board,
    CheckboxOnlyOne,
  },
  props: {
    dataSync: {
      type: Object,
      default: () => {
        return {
          priTermsYn: false,
          termId: "",
        };
      },
      // priTermsYn: Boolean,
      // termId: String,
    },
  },
  methods: {
    getAlertMessage() {
      const priTermsYn = this.dataSync.priTermsYn;

      if (!priTermsYn) return "개인정보 수집 이용동의를 확인해주세요.";

      return null;
    },
  },
};
</script>
