<template>
  <div class="input-bank">
    <div class="input-bank__depositor">
      <Input placeholder="예금주" :isDisabled="isDisabled" :value.sync="bankDepositorSync" />
    </div>
    <div class="input-bank__number">
      <SelectBox
        style="width: 120px"
        placeholder="선택"
        :isDisabled="isDisabled"
        :dataList="bankCodeList"
        :value.sync="bankCodeSync"
      />
      <InputNumber
        placeholder="계좌번호"
        :isDisabled="isDisabled"
        :maxLength="16"
        :value.sync="bankNumberSync"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputTel";
import SelectBox from "@/components/common/selectBox/SelectBox";
import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  name: "InputBank",
  components: {
    Input,
    InputNumber,
    SelectBox,
  },
  props: {
    isDisabled: Boolean,
    bankDepositor: String,
    bankCode: String,
    bankNumber: String,
  },
  created() {
    const { GET_BANK_CODE } = COMMON_CODE_ACTION;
    this.$store.dispatch(GET_BANK_CODE);
  },
  computed: {
    ...mapState({
      bankCodeList: (state) => state.commonCode.bankCodeList,
    }),
    bankDepositorSync: {
      get() {
        return this.bankDepositor;
      },
      set(value) {
        this.$emit("update:bankDepositor", value);
      },
    },
    bankCodeSync: {
      get() {
        return this.bankCode;
      },
      set(value) {
        this.$emit("update:bankCode", value);
      },
    },

    bankNumberSync: {
      get() {
        return this.bankNumber;
      },
      set(value) {
        this.$emit("update:bankNumber", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.input-bank {
  &__dipositor {
  }
  &__number {
    display: flex;
    margin-top: 5px;
    .tf_comm {
      margin-left: 5px;
    }
  }
}
</style>
