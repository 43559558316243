<template>
  <tbody>
    <tr>
      <td rowspan="3" class="align_mid align_center">
        <button
          type="button"
          class="btn_fourthly btn_small"
          :disabled="isDisabled"
          @click="onClickRemoveItem(dataSync)"
        >
          삭제
        </button>
      </td>
      <th>이름<span class="ico_purchase ico_star">필수항목</span></th>
      <td>
        <Input placeholder="이름을 입력하세요" :value.sync="dataSync.name" />
      </td>
      <th>전화번호 (내선)</th>
      <td>
        <InputPhone
          :useMobile="false"
          :useArea="true"
          :phone0.sync="dataSync.tel.phone0"
          :phone1.sync="dataSync.tel.phone1"
          :phone2.sync="dataSync.tel.phone2"
        />
      </td>
    </tr>
    <tr>
      <th>직급<span class="ico_purchase ico_star">필수항목</span></th>
      <td>
        <Input placeholder="직급을 입력하세요" :value.sync="dataSync.jobgrade" />
      </td>
      <th>휴대전화<span class="ico_purchase ico_star">필수항목</span></th>
      <td>
        <InputPhone
          :useMobile="true"
          :useArea="false"
          :phone0.sync="dataSync.mobile.phone0"
          :phone1.sync="dataSync.mobile.phone1"
          :phone2.sync="dataSync.mobile.phone2"
        />
      </td>
    </tr>
    <tr>
      <th>직무<span class="ico_purchase ico_star">필수항목</span></th>
      <td>
        <Input placeholder="직무를 입력하세요" :value.sync="dataSync.job" />
      </td>
      <th>이메일<span class="ico_purchase ico_star">필수항목</span></th>
      <td>
        <InputEmail
          :emailName.sync="dataSync.email.name"
          :emailDomain.sync="dataSync.email.domain"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
import Input from "@/components/common/input/Input";
import InputPhone from "@/components/common/input/InputPhone";
import InputEmail from "@/components/common/input/InputEmail";

export default {
  name: "ManagerInfoItem",
  components: {
    Input,
    InputPhone,
    InputEmail,
  },
  props: {
    isDisabled: Boolean,
    dataSync: Object,
  },
  methods: {
    onClickRemoveItem(item) {
      this.$emit("onClickRemoveItem", item);
    },
  },
};
</script>
