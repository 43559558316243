import PhoneModel from "@/models/PhoneModel";
import EmailModel from "@/models/EmailModel";
import { decodeHTMLEntities } from "@/utils/stringUtils";

export default class ManagerModel {
  constructor() {
    this.name = "";
    this.job = "";
    this.jobgrade = "";
    this.tel = new PhoneModel("02");
    this.mobile = new PhoneModel("010");
    this.email = new EmailModel();

    this.personNum = "";
  }

  setDataFromServer(objRaw) {
    if (objRaw.name) this.name = decodeHTMLEntities(document, objRaw.name);
    if (objRaw.job) this.job = decodeHTMLEntities(document, objRaw.job);
    if (objRaw.jobgrade) this.jobgrade = decodeHTMLEntities(document, objRaw.jobgrade);

    if (objRaw.tel) {
      this.tel = new PhoneModel();
      this.tel.setDataByString(objRaw.tel);
    }
    if (objRaw.mobile) {
      this.mobile = new PhoneModel();
      this.mobile.setDataByString(objRaw.mobile);
    }
    if (objRaw.email) {
      this.email = new EmailModel();
      this.email.setDataByString(objRaw.email);
    }

    if (objRaw.personNum) {
      this.personNum = objRaw.personNum;
    }
  }

  getDataForServer() {
    let obj = {};

    if (this.name) {
      obj.name = this.name;
    }
    if (this.job) {
      obj.job = this.job;
    }
    if (this.jobgrade) {
      obj.jobgrade = this.jobgrade;
    }

    if (this.tel.getIsValid()) {
      obj.tel = this.tel.getTextFull();
    }
    if (this.mobile.getIsValid()) {
      obj.mobile = this.mobile.getTextFull();
    }
    if (this.email.getIsValid()) {
      obj.email = this.email.getTextFull();
    }

    if (this.personNum) {
      obj.personNum = this.personNum;
    }

    return obj;
  }

  getIsValid() {
    if (!this.name) return false;
    if (!this.job) return false;
    if (!this.mobile.getIsValid()) return false;
    if (!this.email.getIsValid()) return false;

    return true;
  }

  getAlertMessage() {
    if (!this.name) return "담당자 이름을 확인해주세요.";
    if (!this.jobgrade) return "담당자 직급를 확인해주세요.";
    if (!this.job) return "담당자 직무를 확인해주세요.";
    if (!this.mobile.getIsValid()) return "담당자 휴대전화를 확인해주세요.";
    if (!this.email.getIsValid()) return "담당자 이메일을 확인해주세요.";

    return null;
  }
}
