<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">회원가입</h2>
      </div>
      <div class="body_section">
        <JoinBasic
          ref="joinBasic"
          :partnerJoinListText="partnerJoinListText"
          :corporateNum="corporateNum"
          :dataSync="basic"
          @onClickMailConfirm="onClickMailConfirm"
        />
        <ManagerInfo :dataSync="managerInfo" />
        <PartnerClause
          ref="partnerClause"
          :dataSync.sync="partners.dataSync"
          :dataList="afficodeList"
          :arrComplete="partners.arrComplete"
          @onClickTerm="onClickTerm"
        />
        <PersonalAgree ref="personalAgree" :dataSync="personalAgree.dataSync" />
        <!-- <InfoAgree ref="infoAgree" :dataSync="infoAgree.dataSync" /> -->
      </div>
    </div>
    <Sticky>
      <button type="button" class="btn_fourthly btn_large" @click="goLogin">취소</button>
      <button type="submit" class="btn_primary btn_large" @click="onClickConfirm">회원가입</button>
    </Sticky>
    <template v-slot:popup>
      <PartnerClausePopup v-if="isPopPartnerTerm" @onClickClose="onClickClosePopPartner" />
      <!-- @onClickConfirm="onClickConfirmPopPartner" -->
      <AlertPopup
        v-if="isPopAlert"
        :alertText="alertText"
        btnPrimaryText="확인"
        @onClickPrimary="onClickConfirmAlert"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import JoinBasic from "@/components/user/join/write/JoinBasic";
import ManagerInfo from "@/components/shared/managerInput/ManagerInfo";
import PartnerClause from "@/components/user/join/write/PartnerClause";
import PersonalAgree from "@/components/user/join/write/PersonalAgree";
import InfoAgree from "@/components/user/join/write/InfoAgree";
import Sticky from "@/components/layout/content/Sticky.vue";
import PartnerClausePopup from "@/components/user/join/write/PartnerClausePopup";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import { getPhoneTextFull } from "@/utils/phoneUtils";
import { getEmailTextFull } from "@/utils/emailUtils";

export default {
  name: "Join",
  components: {
    PageWithLayout,
    JoinBasic,
    ManagerInfo,
    PartnerClause,
    PersonalAgree,
    InfoAgree,
    Sticky,
    PartnerClausePopup,
    AlertPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      basic: {
        loginPass: "",
        companyName: "",
        ceo: "",
        tel: {
          phone0: "",
          phone1: "",
          phone2: "",
        },
        fax: {
          phone0: "",
          phone1: "",
          phone2: "",
        },
        address: "",
        address2: "",
        zipcode: "",
        email: {
          name: "",
          domain: "",
        },
        business: "", // 업태
        businessType: "", // 업종
        siteUrl: "",
        attachFileList: [],
        businessRegFileList: [],
        bankDepositor: "",
        bankCode: "",
        bankNumber: "",
        bankBookFileList: [],
      },
      managerInfo: {
        dataList: [],
      },
      partners: {
        dataSync: {
          checkedNames: [],
        },
        arrComplete: [],
      },
      personalAgree: {
        dataSync: {
          priTermsYn: false,
          termId: "1",
        },
      },
      infoAgree: {
        dataSync: {
          infoTermsYn: false,
          termId: "1",
        },
      },
      isPopPartnerTerm: false,
      currentItem: null,
      isPopAlert: false,
      alertText: "",
      corporateNum: "",

      afficodeList: [],

      isCompleteJoin: false,

      companyTermText: "",
      companyTermTitle: "",

      emailCheck: false,
    };
  },
  computed: {
    partnerJoinListText() {
      const reducer = (accumulator, currentValue) => {
        if (!accumulator) return currentValue.companyName;

        return accumulator + ", " + currentValue.companyName;
      };

      const ret = this.afficodeList.reduce(reducer, "");

      return ret;
    },
  },
  beforeMount() {
    // 가입 여부 확인 안하고 바로 들어왔을 경우 돌려보냄.

    if (!this.$route.params.corporateNum || !this.$route.params.afficodeList) {
      this.$router.push(this.$routerPath.JOIN_INQUIRY);
      return;
    }

    this.corporateNum = this.$route.params.corporateNum;

    this.afficodeList = this.$route.params.afficodeList;

    this.partners.arrComplete = this.afficodeList.map((item) => {
      const ret = {
        companyCode: item.companyCode,
        companyName: item.companyName,
        //isComplete: false
      };
      return ret;
    });
  },
  methods: {
    async onClickMailConfirm() {
      var alertMess = null;

      if (!this.basic.email.name) alertMess = "메일 정보를 입력해주세요.";
      if (!this.basic.email.domain) alertMess = "메일 정보를 입력해주세요.";

      if (alertMess) {
        this.alert(alertMess);
        return;
      }

      const email = `${this.basic.email.name}@${this.basic.email.domain}`;

      const path = `${this.$apiPath.MAIL_CONFIRM}?subject=파트너 가입 메일인증&to=${email}`;

      const objSend = {};

      // 파트너 리스트 같이 보내려면 주석 제거 - to brian.
      objSend.partnerJoinList = this.getPartnerJoinList();

      const result = await ApiService.shared.postData(path, objSend);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.alert(
        `대표이메일로 메일이 발송되었습니다.<br />메일 하단의 <strong>'메일인증 확인'</strong>버튼을 클릭하시어<br /> 인증 완료 부탁드립니다.`,
      );
    },
    alertMessage() {},
    onClickConfirm() {
      // this.alertMessage();
      if (this.emailCheck) {
        let alertMessage = this.$refs.joinBasic.getAlertMessage();
        if (alertMessage) {
          this.alertText = alertMessage;
          this.isPopAlert = true;
          return;
        }

        alertMessage = this.getManagerInfoAlertMessage();
        if (alertMessage) {
          this.alertText = alertMessage;
          this.isPopAlert = true;
          return;
        }

        alertMessage = this.$refs.partnerClause.getAlertMessage();
        if (alertMessage) {
          this.alertText = alertMessage;
          this.isPopAlert = true;
          return;
        }

        alertMessage = this.$refs.personalAgree.getAlertMessage();
        if (alertMessage) {
          this.alertText = alertMessage;
          this.isPopAlert = true;
          return;
        }
      }
      const obj = this.makeObjectForSave();
      this.join(obj);
    },
    getPartnerJoinList() {
      const ret = this.afficodeList.map((item) => {
        const obj = { affiCode: item.companyCode };
        return obj;
      });

      return ret;
    },
    makeObjectForSave() {
      const obj = {
        // "loginId": "nobody",
        loginId: this.corporateNum,

        useTermsYn: "Y", // 알단 필수니까 Y로 하겠다.

        // "status": "P",

        partnerJoinList: this.getPartnerJoinList(),

        zipcode: this.basic.zipcode,
        address: this.basic.address,
        address2: this.basic.address2,
        siteUrl: this.basic.siteUrl,

        corporateNum: this.corporateNum,
        business: this.basic.business,
        businessType: this.basic.businessType,
        ceo: this.basic.ceo,
        companyName: this.basic.companyName,
        loginPass: this.basic.loginPass,
        tel: getPhoneTextFull(this.basic.tel),
        email: getEmailTextFull(this.basic.email),

        partnerPersonList: this.convertDataToSend(this.managerInfo.dataList),
        bankDepositor: this.basic.bankDepositor,
        bankCode: this.basic.bankCode,
        bankNumber: this.basic.bankNumber,

        priTermsYn: this.personalAgree.dataSync.priTermsYn ? "Y" : "N",
        infoTermsYn: this.infoAgree.dataSync.infoTermsYn ? "Y" : "N",
      };

      if (this.basic.attachFileList.length > 0) {
        obj.attachFileList = this.basic.attachFileList;
      }
      if (this.basic.businessRegFileList.length > 0) {
        obj.businessRegFile = this.basic.businessRegFileList[0];
      }

      if (this.basic.bankBookFileList.length > 0) {
        obj.bankBookFile = this.basic.bankBookFileList[0];
      }
      const fax = this.basic.fax;
      if (fax.phone0.trim() && fax.phone1.trim() && fax.phone2.trim()) {
        obj.fax = getPhoneTextFull(fax);
      }

      return obj;
    },
    async join(obj) {
      const result = await ApiService.shared.postData(this.$apiPath.PARTNER, obj);

      if (result.code !== "200") {
        this.alertText = result.text;
        this.isPopAlert = true;
        this.emailCheck = true;
        return;
      }
      if (!result.data) {
        this.alertText = `이메일 인증을 완료해주세요.`;
        this.isPopAlert = true;
        return;
      }

      this.isCompleteJoin = true;
      // this.alertText = `대표 이메일로 인증 메일이 발송되었습니다.<br>메일 내 인증을 료해주시길 바랍니다.<br><br>회원가입 진행 사항은 입력하신 대표 이메일로 발송됩니다.`
      this.alertText = `회원가입 요청이 완료되었습니다.<br>가입 승인 완료 시 대표 이메일로 안내 메일이 발송됩니다.<br><br>* 회원가입 승인 완료 후 로그인 가능합니다.`;
      this.isPopAlert = true;
    },
    getManagerInfoAlertMessage() {
      const dataList = this.managerInfo.dataList;

      var alertMessage = null;

      for (var i = 0; i < dataList.length; i++) {
        const item = dataList[i];

        alertMessage = item.getAlertMessage();

        if (alertMessage) break;
      }

      return alertMessage;
    },
    onClickConfirmAlert() {
      if (this.isCompleteJoin) {
        this.goLogin();

        return;
      }

      this.isPopAlert = false;
    },
    async onClickTerm() {
      this.isPopPartnerTerm = true;
    },
    onClickClosePopPartner() {
      this.isPopPartnerTerm = false;
    },
    // onClickConfirmPopPartner() {
    //   this.currentItem.isComplete = true;

    //   this.currentItem = null;

    //   this.isPopPartnerTerm = false;
    //   this.companyTermText = '';
    //   this.companyTermTitle = '';
    // },
    convertDataToSend(dataList) {
      const arrRet = dataList.map((item) => item.getDataForServer());

      return arrRet;
    },
    goLogin() {
      this.$router.push({
        path: `${this.$routerPath.LOGIN}`,
      });
    },
  },
};
</script>
