<template>
  <TableViewWithTitle title="기본 정보">
    <template v-slot:body>
      <!-- <tr>
        <th>가입 공동체명</th>
        <td colspan="3" class="td_text">{{ partnerJoinListText }}</td>
      </tr> -->
      <tr>
        <th>ID (사업자 번호)</th>
        <td colspan="3">
          {{ corporateNum }}
        </td>
      </tr>
      <tr>
        <th>비밀번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <Input
              type="password"
              class="w208"
              :maxLength="20"
              placeholder="영문/숫자/특수문자 포함 20자 이내"
              :value.sync="dataSync.loginPass"
            />
            <p :class="['txt_view_r', dataSync.loginPass && !isPassFormat ? 'tc_red' : 'tc_b']">
              * 영문과 숫자를 조합하여 8자 이상 20자 이내로 작성 (특수 문자 입력 포함)
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <th>비밀번호 확인<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <Input
              type="password"
              class="w208"
              :maxLength="20"
              placeholder="비밀번호 확인"
              :value.sync="loginPassConfirm"
            />
            <p class="txt_view_r tc_b">* 입력된 비밀번호와 동일하게 작성</p>
            <p v-if="loginPassConfirm && !isPassContirm" class="txt_view_r tc_red">
              * 비밀번호가 일치하지 않습니다.
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <th>상호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input
            placeholder="사업자등록증과 동일한 상호명을 입력하세요"
            :value.sync="dataSync.companyName"
          />
        </td>
        <th>대표자<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input
            placeholder="사업자등록증과 동일한 대표명으로 입력하세요"
            :value.sync="dataSync.ceo"
          />
        </td>
      </tr>
      <tr>
        <th>대표 전화번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputPhone
            :useMobile="false"
            :useArea="true"
            :phone0.sync="dataSync.tel.phone0"
            :phone1.sync="dataSync.tel.phone1"
            :phone2.sync="dataSync.tel.phone2"
          />
        </td>
        <th>FAX</th>
        <td>
          <InputPhone
            :useMobile="false"
            :useArea="true"
            :phone0.sync="dataSync.fax.phone0"
            :phone1.sync="dataSync.fax.phone1"
            :phone2.sync="dataSync.fax.phone2"
          />
        </td>
      </tr>
      <tr>
        <th>본사 주소<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputAddress
            :zipcode.sync="dataSync.zipcode"
            :address.sync="dataSync.address"
            :address2.sync="dataSync.address2"
            addressTip="* 사업자등록증 본사 주소지 입력"
          />
        </td>
      </tr>
      <tr>
        <th>대표 이메일<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputEmail
            :isMailPushBtn="true"
            :emailName.sync="dataSync.email.name"
            :emailDomain.sync="dataSync.email.domain"
            @onClickMailConfirm="onClickMailConfirm"
          />
          <template>
            <!-- 이메일 문구 넣어야됨 -->
          </template>
        </td>
      </tr>
      <tr>
        <th>업태<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input
            placeholder="사업자 등록증 기준으로 업태를 최대 3개까지 입력하세요"
            :value.sync="dataSync.business"
          />
        </td>
        <th>업종<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input
            placeholder="사업자 등록증 기준으로 업종을 최대 3개까지 입력하세요"
            :value.sync="dataSync.businessType"
          />
        </td>
      </tr>
      <tr>
        <th>사업자등록증<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <FileWrite
            :canMultiple="false"
            :isNeedAuth="false"
            :dataList="dataSync.businessRegFileList"
            :invalidFileExtension="invalidFileExtensions"
            @fileUploaded="(file) => (dataSync.businessRegFileList = file)"
            @fileRemoved="(fileToRemove) => (dataSync.businessRegFileList = [])"
          />
        </td>
      </tr>
      <tr>
        <th>계좌번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td class="align_top">
          <InputBank
            :bankDepositor.sync="dataSync.bankDepositor"
            :bankCode.sync="dataSync.bankCode"
            :bankNumber.sync="dataSync.bankNumber"
          />
        </td>
        <th>통장사본<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <FileWrite
            :canMultiple="false"
            :isNeedAuth="false"
            :dataList="dataSync.bankBookFileList"
            :invalidFileExtension="invalidFileExtensions"
            @fileUploaded="(file) => (dataSync.bankBookFileList = file)"
            @fileRemoved="(fileToRemove) => (dataSync.bankBookFileList = [])"
          />
        </td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <!-- 기존 필수 항목에서 제외 -->
        <td colspan="3">
          <FileWrite
            :isNeedAuth="false"
            :dataList="dataSync.attachFileList"
            :invalidFileExtension="invalidFileExtensions"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
          <span>
          ※ 필수 첨부 파일<br/>
          회사소개서 (개요, 조직인원현황, 사업범위, 주요 레퍼런스, 제품소개 등), 재무제표 (직전 2개년), 신용평가서, 공사면허 (공사계약시 첨부)          
          </span>
        </td>
      </tr>
      <tr>
        <th>참고 URL</th>
        <td colspan="3">
          <Input
            :maxLength="MAX_LENGTH_URL"
            placeholder="회사 홈페이지 주소를 입력하세요"
            :value.sync="dataSync.siteUrl"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import InputPhone from "@/components/common/input/InputPhone";
import InputBank from "@/components/common/input/InputBank";

import InputEmail from "@/components/common/input/InputEmail";
import InputAddress from "@/components/common/input/InputAddress";
import FileWrite from "@/components/common/file/FileWrite";
import { file } from "@/constants/options";
import { MAX_LENGTH_URL } from "@/constants/commonTextLength";

export default {
  name: "JoinBasic",
  components: {
    TableViewWithTitle,
    Input,
    InputPhone,
    InputEmail,
    InputBank,
    InputAddress,
    FileWrite,
  },
  props: {
    partnerJoinListText: String, // 파트너 조인 리스트
    corporateNum: String,
    dataSync: Object,
  },
  data() {
    return {
      loginPassConfirm: "",
    };
  },
  computed: {
    invalidFileExtensions() {
      return file.INVALID_FILE_EXTENSIONS;
    },
    isPassFormat() {
      const str = this.dataSync.loginPass;

      // 입력 안하면 패스 실패.
      if (!str) return false;

      const reg1 = /^[a-z0-9]{8,12}$/;
      const reg2 = /[a-z]/g;
      const reg3 = /[0-9]/g;

      //return reg1.test(str) && reg2.test(str) && reg3.test(str);
      return true;

      // if(str != ''){
      //   return(reg1.test(str) &&  reg2.test(str) && reg3.test(str));
      // }else{
      //   return true
      // }
    },
    isPassContirm() {
      if (!this.loginPassConfirm) return false;

      return this.dataSync.loginPass === this.loginPassConfirm;

      // if(this.dataSync.loginPass != '' && this.loginPassConfirm != ''){
      //   return this.dataSync.loginPass === this.loginPassConfirm
      // }else{
      //   return true
      // }
    },
    MAX_LENGTH_URL() {
      return MAX_LENGTH_URL;
    },
  },
  methods: {
    getAlertMessage() {
      if (!this.isPassFormat) return "비밀번호를 확인해주세요.";
      if (!this.isPassContirm) return "비밀번호를 확인해주세요.";

      if (!this.dataSync.companyName.trim()) return "상호를 확인해주세요.";
      if (!this.dataSync.ceo.trim()) return "대표자를 확인해주세요.";

      if (!this.dataSync.tel.phone0.trim()) return "대표 전화번호를 확인해주세요.";
      if (!this.dataSync.tel.phone1.trim()) return "대표 전화번호를 확인해주세요.";
      if (!this.dataSync.tel.phone2.trim()) return "대표 전화번호를 확인해주세요.";

      if (!this.dataSync.zipcode.trim()) return "본사 주소를 확인해주세요.";
      if (!this.dataSync.address.trim()) return "본사 주소를 확인해주세요.";
      if (!this.dataSync.address2.trim()) return "본사 주소를 확인해주세요.";

      if (!this.dataSync.email.name.trim()) return "대표 이메일을 확인해주세요.";
      if (!this.dataSync.email.domain.trim()) return "대표 이메일을 확인해주세요.";

      if (!this.dataSync.business.trim()) return "업태를 확인해주세요.";
      if (!this.dataSync.businessType.trim()) return "업종을 확인해주세요.";

      // if( this.dataSync.attachFileList.length <= 0 ) return '첨부 파일을 확인해주세요.';
      if (this.dataSync.businessRegFileList.length <= 0) return "사업자등록증을 확인해주세요.";
      if (!this.dataSync.bankDepositor) return "예금주를 확인해주세요.";
      if (!Number(this.dataSync.bankCode) > 0) return "은행은 확인해주세요";
      if (!this.dataSync.bankNumber) return "계좌번호를 확인해주세요";
      if (this.dataSync.bankBookFileList.length <= 0) return "통장 사본을 확인해주세요.";

      return null;
    },
    CheckPass(str) {
      const reg1 = /^[a-z0-9]{7,14}$/; // a-z 0-9 중에 7자리 부터 14자리만 허용 한다는 뜻이구요
      const reg2 = /[a-z]/g;
      const reg3 = /[0-9]/g;
      return reg1.test(str) && reg2.test(str) && reg3.test(str);
    },
    fileUploaded(fileList) {
      this.dataSync.attachFileList = this.dataSync.attachFileList.concat(fileList);
    },
    fileRemoved(file) {
      this.dataSync.attachFileList = this.dataSync.attachFileList.filter((item) => item !== file);
    },
    onClickMailConfirm() {
      this.$emit("onClickMailConfirm");
    },
  },
};
</script>
