<template>
  <Popup popupType="tableType" width="940px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">LIG시스템 이용약관</h3>
      </div>
      <div class="body_section">
        <div class="box_detail">
          <div class="inner_detail">
            <em class="emph_term"> LIG시스템 전자구매 시스템 이용약관</em>
            <strong class="tit_term">제1조 (목적)</strong>
            <p class="desc_term">
              본 약관은 주식회사 LIG시스템(이하 "회사")가 거래를 희망하는 업체(이하 “협력사”)에게
              제공하는 LIG시스템 전자구매 시스템 (이하 "시스템")의 이용조건 및 절차에 관한 사항,
              권리/의무 및 책임사항을 규정함을 목적으로 합니다.
            </p>
            <strong class="tit_term">제2조 (용어의 정의)</strong>
            <p class="desc_term">본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <ul class="list_term">
              <li>
                1. 협력사: 회사가 요청하는 정보를 제공하여 회원 등록을 한 자로서, 약관에 따라
                시스템을 통해 견적서 제출 및 거래 등 서비스를 이용할 수 있는 업체를 말합니다.
              </li>
              <li>
                2. 시스템 : 회사와 협력사간에 이루어지는 거래를 위해 인터넷상에 구축한 시스템을
                말합니다.
              </li>
              <li>
                3. 협력사 ID : 협력사식별과 협력사의 시스템 이용을 위하여 협력사가 정하고 회사가
                승인한 고유식별번호를 말합니다.
              </li>
              <li>
                4. 비밀번호 : 협력사가 시스템에 접속 및 통신상의 비밀을 보호하기 위해 선정한
                비공개성 문자와 숫자의 조합을 말합니다.
              </li>
              <li>
                5. 발주서 : 회사가 시스템에 등록하는 문서로서, 협력사로부터 구매하는 대상 및 관련
                거래조건 등이 명시된 것을 말합니다.
              </li>
              <li>
                6. 견적서 : 협력사가 시스템에 등록하는 문서로서, 협력사가 회사에 제공할 수 있는 대상
                및 관련 거래조건 등이 명시된 것을 말합니다.
              </li>
            </ul>
            <strong class="tit_term">제3조 (약관의 적용)</strong>
            <ul class="list_term">
              <li>
                1. 본 약관은 회사가 시스템을 통하여 공지하거나 전자우편 기타의 방법으로 고지하고
                협력사가 동의함으로써 효력을 발생합니다.
              </li>
              <li>
                2. 회사는 필요할 경우 전자문서 및 전자거래 기본법, 정보통신망 이용촉진 및 정보보호
                등에 관한 법률 등 관련법령이 허용하는 범위 내에서 사전 고지 없이 약관을 개정할 수
                있으며, 이 경우 개정된 약관을 시스템에 공지합니다.
              </li>
              <li>
                3. 회사가 약관을 개정할 경우에는 기존 약관과 개정약관 및 개정약관의 적용 일자와
                개정사유를 명시하여 현행 약관과 함께 그 적용일자 일십오(15)일 전부터 적용일 이후
                상당한 기간 동안, 개정 내용이 회원에게 불리한 경우에는 그 적용일자 삼십(30)일 전부터
                적용일 이후 상당한 기간 동안 각각 이를 시스템에 공지하고 기존 협력사에게는 협력사가
                등록한 전자우편 주소로 개정약관을 발송하여 고지합니다.
              </li>
              <li>
                4. 개정약관은 그 적용일자 이후에 등록되는 발주서 및 견적서에만 적용되고 그 이전에
                등록된 문서는 개정 전의 약관이 적용됩니다.
              </li>
              <li>
                5. 협력사는 개정된 약관에 동의하지 아니하는 경우 본인의 회원등록을 취소(회원탈퇴)할
                수 있으며, 개정 약관 시행일 칠(7)일 후까지 거부하지 않고 계속 사용하는 경우 약관
                개정에 대한 동의로 간주되고 개정된 약관은 공지와 동시에 그 효력이 발생됩니다.
              </li>
              <li>
                6. 본 약관은 시스템에서의 발주서 및 견적서 등록 등 거래 대상 선정과 관련한 이용
                사항에만 적용되며, 회사와 거래대상자로 선정된 협력사간의 구체적인 거래조건 등에 관한
                사항은 이후 체결될 별도 계약의 적용을 받습니다.
              </li>
              <li>7. 본 약관에 명시되어 있지 않은 사항은 관련 법령의 규정 및 상관례에 따릅니다.</li>
            </ul>
            <strong class="tit_term">제4조 (시스템의 제공 내용)</strong>
            <p class="desc_term">
              회사가 시스템을 통해 제공하는 서비스는 다음과 같으며, 회사는 필요한 경우 서비스의
              내용을 추가 또는 변경하여 제공할 수 있습니다.
            </p>
            <ul class="list_term">
              <li>1. 회사의 구매 정책 안내</li>
              <li>2. 회사의 발주서 등록 및 협력사의 견적서 등록</li>
              <li>3. 기타 회사가 제공하는 각종 정보 제공</li>
            </ul>
            <strong class="tit_term">제5조 (시스템의 장애 및 운영중단)</strong>
            <ul class="list_term">
              <li>
                1. 회사는 컴퓨터 등 정보통신설비의 보수점검/교체, 고장/장애 (시스템 다운, 네트워크
                장애, 통신두절), 외부 해킹 발생 등의 사유로 시스템 서비스 제공을 일시적으로 중단할
                수 있습니다.
              </li>
              <li>
                2. 회사가 통제할 수 없는 전쟁, 지진, 재난 등 천재지변이나 불가항력적인 사유가
                발생하여 시스템 서비스가 불가능하다고 판단될 경우, 회사는 시스템 재개를 위하여
                필요한 조치를 취할 수 있습니다.
              </li>
              <li>
                3. 본 조 제1항 및 제2항에 의한 서비스 중단의 경우에 회사는 제11조에서 정한 방법으로
                협력사에게 통지합니다.
              </li>
              <li>
                4. 회사는 본 조의 사유로 서비스 제공이 일시적으로 중단됨으로 인하여 협력사 또는
                제3자가 입은 손해에 대하여 배상하지 아니합니다. 단, 회사에 고의 또는 중과실이 있는
                경우에는 그러하지 아니합니다.
              </li>
            </ul>
            <strong class="tit_term">제6조 (협력사 등록)</strong>
            <ul class="list_term">
              <li>
                1.시스템에 신규 등록을 희망하는 업체는 회사가 정한 가입양식에 따라 회원정보를 기입한
                후 본 약관에 동의한다는 의사표시를 함으로서 협력사 등록을 신청합니다.
              </li>
              <li>
                2.회사는 다음 각 호에 해당하는 경우 제1항에 따라 신청한 자에 대해 협력사 등록을 거절
                할 수 있습니다.
                <ul class="list_term2">
                  <li>가. 등록 내용에 허위, 기재 누락, 오기가 있는 경우</li>
                  <li>나. 타인의 명의를 사용하여 신청한 경우</li>
                  <li>
                    다. 본 약관 제7조에 의하여 협력사자격을 상실한 적이 있는 경우 (단, 협력사
                    자격상실 후 2년이 경과한 경우는 예외로 한다.)
                  </li>
                  <li>라. 기타 소정의 이용 신청 조건을 충족하지 못하는 경우</li>
                </ul>
              </li>
              <li>
                3. 회사는 내부규정에 의하여 평가를 실시한 후 적격 협력사에 대하여 본 약관 제11조에서
                정한 방법으로 등록 승인을 알립니다. 이 경우, 협력사 등록 효력 발생 시점은 회사의
                승인이 협력사에게 통보된 시점으로 합니다.
              </li>
              <li>
                4. 협력사로 등록된 업체는 등록사항에 변경이 있는 경우 즉시 정보를 직접 수정 및
                등록해야 할 의무가 있으며, 동시에 회사에 해당 변경사항을 알려야 합니다. 변경사항을
                통보하지 않음으로 인하여 입는 불이익은 협력사가 부담합니다.
              </li>
            </ul>
            <strong class="tit_term">제7조 (협력사 탈퇴 및 자격 상실 등)</strong>
            <ul class="list_term">
              <li>
                1. 협력사는 언제든지 협력사 탈퇴를 시스템 내에서 요청할 수 있으며, 회사는 즉시 이에
                응합니다.
              </li>
              <li>
                2. 회사는 협력사가 다음 각 호의 사유에 해당하는 경우, 협력사의 자격을 제한/정지 및
                상실시킬 수 있습니다.
                <ul class="list_term2">
                  <li>가. 본 약관의 내용을 위반하거나 법령에 반하는 행위를 하는 경우</li>
                  <li>
                    나. 타인의 이용을 방해하거나 그 정보를 도용하는 등 운영 질서를 위협하는 경우
                  </li>
                  <li>다. 회사 사업 및 서비스에 치명적인 손해를 끼친 경우</li>
                </ul>
              </li>
              <li>
                3. 회사가 협력사 자격을 제한/정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일
                이내에 그 사유가 시정되지 아니하는 경우 회사는 협력사의 자격을 상실시킬 수 있습니다.
              </li>
              <li>
                4. 회사가 협력사의 자격을 상실시키는 경우, 이를 협력사에게 통지하고 탈퇴 처리합니다.
                협력사 자격이 상실된 업체는 향후 2년간 회사의 협력사로 등록할 수 없습니다.
              </li>
            </ul>
            <strong class="tit_term">제8조 (협력사 ID 및 비밀번호에 대한 의무)</strong>
            <ul class="list_term">
              <li>
                1. 협력사는 협력사 ID와 비밀번호를 통하여 시스템에 접속함으로써 시스템을 이용할 수
                있습니다.
              </li>
              <li>
                2. 협력사 ID 및 비밀번호의 관리책임은 협력사에게 있으며 관리 소홀, 부정 사용에
                의하여 발생하는 모든 결과에 대한 책임은 협력사에게 있습니다.
              </li>
              <li>
                3. 협력사는 협력사 ID와 비밀번호를 타인에게 양도하거나 사용하게 할 수 없습니다.
              </li>
              <li>
                4. 협력사는 협력사 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한
                경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야 합니다.
              </li>
              <li>
                5. 회사의 고의 또는 중과실에 의하지 않은 협력사 ID 및 비밀번호 유출에 대하여 회사는
                책임이 없으며, 이로 인하여 발생하는 손해에 대하여도 책임지지 않습니다.
              </li>
            </ul>
            <strong class="tit_term">제9조 (협력사의 책임과 의무)</strong>
            <ul class="list_term">
              <li>
                1. 협력사는 관계 법령, 본 약관의 규정, 이용안내 및 주의사항 등 회사가 통지하는
                사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하지 않습니다.
              </li>
              <li>
                2. 협력사는 회사의 사전 승낙 없이 시스템을 이용하여 어떠한 영리 행위도 할 수
                없습니다.
              </li>
              <li>
                3. 협력사는 시스템을 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경,
                번역, 출판, 방송 기타의 방법으로 사용하거나 이를 제3자에게 제공할 수 없습니다.
              </li>
              <li>
                4. 협력사는 시스템 이용과 관련하여 다음 각 호의 행위를 하지 않아야 하며, 다음 행위를
                함으로써 발생하는 모든 결과에 대한 책임은 협력사에게 있습니다.
                <ul class="list_term2">
                  <li>가. 다른 협력사의 협력사 ID를 부정하게 사용하는 행위</li>
                  <li>나. 다른 협력사의 전자우편 주소를 취득하여 스팸메일을 발송하는 행위</li>
                  <li>다. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</li>
                  <li>라. 선량한 풍속, 공공질서 또는 기타 사회질서를 해하는 행위</li>
                  <li>마. 회사 및 타인의 명예를 훼손하거나 모욕하는 행위</li>
                  <li>바. 회사 및 타인의 지적재산권 등의 권리를 침해하는 행위</li>
                  <li>사. 해킹 행위 또는 컴퓨터 바이러스의 유포행위</li>
                  <li>
                    아. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위
                  </li>
                  <li>자. 시스템의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
                  <li>차. 회사가 제공하는 시스템의 내용을 변경하는 행위</li>
                  <li>
                    카. 시스템의 이용권한, 기타 계약상 지위를 타인에게 양도, 증여, 담보로 제공하는
                    행위
                  </li>
                  <li>타. 기타 관계법령에 위배되는 행위</li>
                </ul>
              </li>
            </ul>
            <strong class="tit_term">제10조 (회사의 책임과 의무)</strong>
            <ul class="list_term">
              <li>
                1. 회사는 본 약관에서 정한 바에 따라 지속적, 안정적으로 시스템을 제공할 수 있도록
                관련된 설비를 상시 운용할 수 있는 상태로 유지/보수하고, 장애가 발생하는 경우 지체
                없이 수리/복구할 수 있도록 최선의 노력을 다하여야 합니다.
              </li>
              <li>
                2. 회사의 고의 또는 중대한 과실로 협력사가 손해를 입는 경우 입은 배상책임은 회사에
                있습니다. 단, 본 약관 제5조에 의한 시스템의 장애 및 운영중단의 경우에는 책임이
                면제됩니다.
              </li>
              <li>
                3. 회사는 시스템과 관련하여 알게 된 협력사의 개인정보를 본인의 승낙없이 제3자에게
                누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다. 협력사의 개인정보보호에 관한
                기타의 사항은 정보통신망법 및 회사가 별도로 정한 개인정보관리지침에 따릅니다.
              </li>
              <li>
                4. 회사는 시스템을 이용하여 발주서에 따른 거래 협력사로 최종 선정된 협력사뿐 아니라
                선정되지 않은 협력사에게도 제11조에서 정한 방법으로 선정 여부를 통지합니다.
              </li>
            </ul>
            <strong class="tit_term">제11조 (통지 방법)</strong>
            <ul class="list_term">
              <li>
                1. 회사가 협력사에 대한 통지를 하는 경우, 협력사가 시스템 등록 시 제출한 전자우편
                주소 또는 유선번호로 할 수 있습니다.
              </li>
              <li>
                2. 회사가 불특정 다수 회원에 대해 통지하는 경우, 1주일 이상 시스템에 공지로
                게시함으로서 개별 통지에 갈음할 수 있습니다.
              </li>
            </ul>
            <strong class="tit_term">제12조 (권리의 귀속)</strong>
            <ul class="list_term">
              <li>
                1. 견적서에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 협력사에게
                있습니다.
              </li>
              <li>2. 회사가 작성한 모든 게시물의 저작권 및 기타 지적재산권은 회사에 귀속됩니다.</li>
            </ul>
            <strong class="tit_term">제13조 (손해배상 및 면책)</strong>
            <ul class="list_term">
              <li>
                1. 회사는 시스템과 관련하여 협력사에게 어떠한 손해가 발생하더라도, 동 손해가 회사의
                고의 또는 중대한 과실에 의한 경우가 아닌 한 이에 대하여 책임을 부담하지 않습니다.
              </li>
              <li>
                2. 회사는 협력사가 시스템에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에
                관하여는 어떠한 책임을 부담하지 아니합니다.
              </li>
              <li>
                3. 협력사는 자신의 책임 하에 시스템을 이용하며 시스템을 이용하여 게시 또는 전송한
                자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 시스템의 이용과 관련하여
                어떠한 불이익이 발생하더라도 회사는 책임을 부담하지 아니합니다.
              </li>
              <li>
                4. 협력사가 본 약관 규정을 위반하여 회사가 협력사 또는 제 3자에 대하여 책임을
                부담하게 되고 이로써 회사에게 손해가 발생하게 되는 경우, 본 약관을 위반한 협력사는
                회사에게 발생하는 모든 손해를 배상하여야 하며 동 손해로부터 회사를 면책시켜야
                합니다.
              </li>
            </ul>
            <strong class="tit_term">제14조 (전자문서의 효력)</strong>
            <ul class="list_term">
              <li>
                1. 회사가 시스템 상에서 전송한 발주서에 대한 협력사의 전자동의는 일반 계약서에
                정당한 서명 또는 기명날인을 한 것과 동일한 효력을 갖는 것으로 간주됩니다.
              </li>
              <li>
                2. 시스템에서 사용되는 견적서 등 전자문서의 송·수신과 관련한 제반 사항은
                전자거래기본법 등 법령에서 정한 바에 따릅니다.
              </li>
              <li>
                3. 견적서 제출 마감시간 등 시간과 관련한 사항은 시스템 상의 시간을 기준으로 합니다.
              </li>
              <li>
                4. 시스템 상으로 제출된 전자문서는 시스템 내의 DATA 형태로 보관될 수 있습니다.
              </li>
              <li>
                5. 회사와 협력사는 시스템 상의 모든 전자문서는 법률적으로 유효한 문서임을 상호
                인정합니다.
              </li>
            </ul>
            <strong class="tit_term">제15조 (보안유지)</strong>
            <p class="desc_term">
              회사와 협력사는 시스템 상에 제출된 발주서와 견적서 등 전자문서가 내·외부에 공개되지
              않도록 보안에 각별히 유의하도록 합니다.
            </p>
            <strong class="tit_term">제16조 (분쟁의 해결)</strong>
            <ul class="list_term">
              <li>
                1. 회사와 협력사는 시스템과 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한
                모든 노력을 하여야 합니다.
              </li>
              <li>
                2. 본 조 제1항의 규정에도 불구하고 동 분쟁으로 인하여 소송이 제기될 경우, 동 소송은
                서울중앙지방법원 관할로 합니다.
              </li>
            </ul>
            <strong class="tit_term">제17조 (규정의 준용 등)</strong>
            <ul class="list_term">
              <li>
                1. 본 약관을 읽고 동의 박스에 체크하는 방법을 취한 경우 본 약관에 동의한 것으로
                간주합니다.
              </li>
              <li>
                2. 본 약관은 대한민국법령에 의하여 규정되고 이행되며, 본 약관에 명시되지 않은 사항에
                대해서는 관련법령에 의하고 법에 명시되지 않은 부분에 대하여는 관습에 의합니다.
              </li>
            </ul>
            <p class="desc_date">공고일자 : YYYY년 MM월 DD일</p>
            <p class="desc_date">시행일자 : YYYY년 MM월 DD일</p>
          </div>
        </div>
      </div>
    </div>
    <div class="group_btn">
      <button type="submit" class="btn_primary btn_medium" @click="onClickClose">확인</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import { decodeHTMLEntities } from "@/utils/stringUtils";

export default {
  name: "PartnerClausePopup",
  components: {
    Popup,
  },
  props: {},
  computed: {
    termDecoded() {
      const term = this.text;
      if (!term) return "";
      const result = decodeHTMLEntities(document, term);
      return result;
    },
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    // onClickConfirm() {
    //   this.$emit( 'onClickConfirm' );
    // }
  },
};
</script>
