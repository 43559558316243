<template>
  <div class="box_term">
    <div class="inner_term">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsView",
};
</script>
<style scoped>
.box_term {
  padding: 24px 0 24px;
  border: 1px solid #e1e1e1;
  border-top: 1px solid #222;
}
.box_term .inner_term {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 190px;
  padding: 0 24px;
}
.box_term .desc_term {
  font-size: 12px;
  line-height: 19px;
  color: #555;
}
.box_term .desc_term + .desc_term {
  padding-top: 10px;
}
</style>
