<template>
  <div class="area_view">
    <TableHead title="협력사 이용약관" />
    <Board :dataList="dataList" :disableHover="true" :showPagination="false">
      <template v-slot:colgroup>
        <col />
        <col style="width: 243px" />
        <col style="width: 256px" />
      </template>
      <template v-slot:tr>
        <th>업체명</th>
        <th>이용약관</th>
        <th>동의여부</th>
      </template>
      <template v-slot:rows>
        <PartnerClauseItem
          v-for="(item, index) in dataList"
          :key="index"
          :companyCode="item.companyCode"
          :companyName="item.companyName"
          :dataSync="dataSync"
          :isComplete="getIsComplete(item.companyCode)"
          @onClickTerm="onClickTerm(item.companyCode)"
        />
      </template>
    </Board>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import Checkbox from "@/components/common/checkbox/Checkbox";
import PartnerClauseItem from "./PartnerClauseItem";

export default {
  name: "PartnerClause",
  components: {
    TableHead,
    Board,
    Checkbox,
    PartnerClauseItem,
  },
  props: {
    dataSync: {
      type: Object,
      default: () => {
        return {
          checkedNames: [],
        };
      },
      // checkedNames: Array,
    },
    dataList: Array,
    arrComplete: Array,
  },
  methods: {
    getIsComplete(companyCode) {
      const item = this.arrComplete.find((item) => item.companyCode === companyCode);

      if (!item) return false;

      return item.isComplete;
    },
    getAlertMessage() {
      const checkedNames = this.dataSync.checkedNames;
      const dataList = this.dataList;
      let item;

      if (dataList.length != checkedNames.length) return "협력사 이용약관 동의여부를 확인해주세요.";

      if (this.arrComplete.some((item) => item.isComplete === false)) {
        return "협력사 이용약관을 확인해주세요.";
      }

      return null;
    },
    onClickTerm(companyCode) {
      const item = this.arrComplete.find((item) => item.companyCode === companyCode);

      if (!item) return;

      this.$emit("onClickTerm", item);
    },
  },
};
</script>
