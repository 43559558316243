<template>
  <div class="area_view">
    <TableHead title="정보제공 이용동의" />
    <TermsView>
      <p class="desc_term">
        미 가입된 공동체에게 정보를 공개하여 가입요청을 받을 수 있습니다.<br />* 제공되는 정보 :
        회사명, 업종, 사업자 번호, 대표 이메일 주소, 대표 연락처
      </p>
    </TermsView>
    <div class="agree_term">
      <div class="area_right">
        <CheckboxOnlyOne
          id="2"
          :isSelected.sync="dataSync.infoTermsYn"
          text="[선택] 위 내용에 동의합니다."
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import TermsView from "@/components/shared/terms/TermsView";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";

export default {
  name: "InfoAgree",
  components: {
    TableHead,
    TermsView,
    CheckboxOnlyOne,
  },
  props: {
    dataSync: {
      type: Object,
      // infoTermsYn: Boolean,
      // termId: String,
    },
  },
  methods: {
    getIsValid() {
      return true;
    },
  },
};
</script>
