<template>
  <TableViewWithTitle title="담당자 정보" class="area_manager_info">
    <template v-if="canAdd" v-slot:headRight>
      <button type="button" class="btn_secondary btn_medium" @click="onClickAddItem">
        담당자 추가
      </button>
    </template>
    <template v-slot:colgroup>
      <col style="width: 103px" />
      <col style="width: 191px" />
      <col style="width: 324px" />
      <col style="width: 190px" />
      <col style="width: 428px" />
    </template>
    <template v-slot:custom>
      <ManagerInfoItem
        v-for="(item, index) in dataSync.dataList"
        :key="index"
        :isDisabled="dataSync.dataList.length === 1"
        :dataSync="item"
        @onClickRemoveItem="onClickRemoveItem"
      />
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ManagerInfoItem from "./ManagerInfoItem";
import ManagerModel from "@/components/shared/managerInput/ManagerModel";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
    ManagerInfoItem,
  },
  props: {
    dataSync: {
      dataList: [],
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
  },
  beforeMount() {
    if (this.dataSync.dataList.length < 1) {
      const model = new ManagerModel();
      this.dataSync.dataList.push(model);
    }
  },
  methods: {
    onClickAddItem() {
      const model = new ManagerModel();
      this.dataSync.dataList.push(model);
    },
    onClickRemoveItem(itemToRemove) {
      this.dataSync.dataList = this.dataSync.dataList.filter((item) => item !== itemToRemove);
    },
  },
};
</script>

<style scoped>
.area_manager_info /deep/ .tbl_view tbody tr:nth-child(4) th,
.area_manager_info /deep/ .tbl_view tbody tr:nth-child(4) td {
  border-top: 2px solid #e1e1e1;
}
.area_manager_info /deep/ .tbl_view tbody tr:nth-child(3n + 4) td:first-child,
.area_manager_info /deep/ .tbl_view tbody tr:nth-child(3n + 6) th,
.area_manager_info /deep/ .tbl_view tbody tr:nth-child(3n + 6) td {
  border-bottom: 2px solid #e1e1e1;
}
</style>
