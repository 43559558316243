<template>
  <tr>
    <td class="align_left">
      {{ companyName }}
    </td>
    <td>
      <button type="button" class="btn_fourthly btn_small" @click="onClickTerm">
        이용약관 확인
      </button>
      <!-- :disabled="isComplete"  -->
    </td>
    <td>
      <CheckboxItem
        :id="companyCode"
        :checkedNames.sync="dataSync.checkedNames"
        text="[필수] 동의"
      />
    </td>
  </tr>
</template>

<script>
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

export default {
  name: "PartnerClauseItem",
  components: {
    CheckboxItem,
  },
  props: {
    companyCode: String,
    companyName: String,
    isComplete: Boolean,
    dataSync: {
      type: Object,
      default: () => {
        return {
          checkedNames: [],
        };
      },
      // checkedNames: Array,
    },
  },
  methods: {
    onClickTerm() {
      this.$emit("onClickTerm");
    },
  },
};
</script>
